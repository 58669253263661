import {
  getFormDefinitionTabbed,
  getFormDefinitionGeneral,
  createFormDataFromItem,
  createItemFromFormData
} from './form';
import { entityToStore } from '@/utils/store';
import baseCrudMixin from '@/mixins/crud';
export default {
  computed: {
    definitionEdit() {
      return this.createDefinitionEdit();
    },
    definitionCreate() {
      return this.createDefinitionCreate();
    },
    entityStore() {
      return entityToStore(this.entity);
    }
  },
  mixins: [baseCrudMixin],
  data: () => ({
    entity: 'ScratchAndWinAction',
    returnTo: 'ScratchAndWinActions'
  }),
  methods: {
    getDependencies(data) {
      return [];
    },
    createDefinitionEdit() {
      return getFormDefinitionTabbed(this);
    },
    createDefinitionCreate() {
      return getFormDefinitionGeneral(this);
    },
    createFormDataFromItem,
    createItemFromFormData
  }
};
