<template>
  <div>
    <embedded-crud-table
      :entity="entity"
      :entity-store="entityStore"
      :columns="columns"
      :generate-summary="generateSummary"
      :definition="definition"
      :caption="caption"
      :disabled="disabled"
      :allow-create="true"
      :parent-id-filter-field="parentIdFilterField"
      :open-in-fullscreen="openInFullscreen"
      :required="required"
      :create-item-from-form-data="createItemFromFormData"
      :create-form-data-from-item="createFormDataFromItem"
      :permission-code="'ScratchAndWinAction'"
      :parent-i-d="parentID"
    />
  </div>
</template>

<script>
import EmbeddedCrudTable from '@/components/crud/EmbeddedCrudTable';
import formMixin from './form-mixin';
import { formatOption } from '@/utils/filters';

export default {
  components: {
    EmbeddedCrudTable
  },
  mixins: [formMixin],
  props: {
    caption: String,
    parentIdFilterField: {
      type: String,
      required: true
    },
    permissionCode: String,
    disabled: {
      default: false,
      type: Boolean
    },
    openInFullscreen: {
      default: false,
      type: Boolean
    },
    required: {
      default: false,
      type: Boolean
    },
    parentID: Number
  },
  data() {
    return {
      entity: 'ScratchAndWinBatch',
      returnTo: 'ScratchAndWinBatches',
      columns: [
        {
          field: 'scratchAndWinPriceType',
          label: 'scratchAndWin.scratchAndWinPriceType',
          minWidth: '150px',
          formatter: formatOption(this, 'scratchAndWinPriceType'),
          filter: {
            type: 'select',
            options: this.$store.getters['option/loadedOptions']('scratchAndWinPriceType'),
            valueField: 'code',
            labelField: 'description'
          }
        },
        {
          field: 'couponType',
          label: 'scratchAndWin.couponType',
          minWidth: '150px',
          formatter: formatOption(this, 'couponType'),
          filter: {
            type: 'select',
            options: this.$store.getters['option/loadedOptions']('couponType'),
            valueField: 'code',
            labelField: 'description'
          }
        },
        { field: 'productName', label: 'scratchAndWin.product' },
        { field: 'numberOfCards', label: 'scratchAndWin.numberOfCards' },
        {
          field: 'batchState',
          label: 'scratchAndWin.batchState',
          minWidth: '100px',
          formatter: formatOption(this, 'scratchAndWinBatchState'),
          filter: {
            type: 'select',
            options: this.$store.getters['option/loadedOptions']('scratchAndWinBatchState'),
            valueField: 'code',
            labelField: 'description'
          },
          sortable: false
        }
      ],
      type: Array
    };
  },
  methods: {
    generateSummary({ numberOfCards }) {
      return `${numberOfCards}`;
    }
  }
};
</script>

<style lang="scss" scoped></style>
