import { getFormDefinition, createFormDataFromItem, createItemFromFormData } from './form';
import { entityToStore } from '@/utils/store';
import baseCrudMixin from '@/mixins/crud';
import { CouponType } from '@/utils/constants';
export default {
  computed: {
    definition() {
      return this.createDefinition();
    },
    entityStore() {
      return entityToStore(this.entity);
    },
    allowedCouponTypeOptions() {
      const options = this.$store.getters['option/loadedOptions']('couponType') ?? [];
      return options.filter(e => e.code === CouponType.FreeProduct);
    },
    scratchAndWinBatchTypeOptions() {
      const options = this.$store.getters['option/loadedOptions']('couponType') ?? [];
      return options.filter(e => e.code === CouponType.FreeProduct);
    }
  },
  mixins: [baseCrudMixin],
  data: () => ({
    entity: 'ScratchAndWinBatch',
    returnTo: 'ScratchAndWinBatches'
  }),
  methods: {
    getDependencies(data) {
      return [];
    },
    createDefinition() {
      return getFormDefinition(this);
    },
    createFormDataFromItem,
    createItemFromFormData
  }
};
