import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';
import DateTimePicker from '@/components/DSE/DateTimePicker';
import CrudBatches from './batches/crudBatches';
import NumericInput from '@/components/DSE/NumericInput';
import FormButton from '@/components/DSE/FormButton';
import TextArea from '@/components/DSE/TextArea';

export function getFormDefinitionTabbed(vue) {
  return {
    type: 'tab',
    showFormLabels: [true, false],
    labels: ['common.tabGeneral', 'scratchAndWin.tabBatches'],
    groups: [
      getFormDefinitionGeneral(vue),
      {
        batches: {
          type: CrudBatches,
          defaultValue: [],
          props: {
            caption: 'common.emptyString',
            openInFullscreen: false,
            parentIdFilterField: 'scratchAndWinActionID',
            permissionCode: 'ScratchAndWinAction'
          }
        }
      }
    ]
  };
}

export function getFormDefinitionGeneral(vue) {
  return {
    printButton: {
      type: FormButton,
      hideOnCreate: true,
      props: {
        caption: 'common.emptyString',
        buttonText: 'scratchAndWin.downloadCsv',
        onclick: vue.downloadCsvAfterConfirm,
        icon: 'el-icon-download'
      }
    },
    name: {
      type: MDinput,
      defaultValue: ' ',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    startDate: {
      type: DateTimePicker,
      props: {
        caption: 'scratchAndWin.startDate',
        type: 'date',
        format: vue.$store.getters.dateFormat,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    endDate: {
      type: DateTimePicker,
      props: {
        caption: 'scratchAndWin.endDate',
        type: 'date',
        format: vue.$store.getters.dateFormat,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    couponValidDays: {
      type: NumericInput,
      defaultValue: 31,
      props: {
        type: 'number',
        precision: 0,
        caption: 'scratchAndWin.couponValidDays',
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    winText: {
      type: TextArea,
      defaultValue: '',
      props: {
        maxRows: 1024,
        caption: 'scratchAndWin.winText'
      },
      rules: [{ validator: validatorRequire }]
    }
  };
}

export default getFormDefinitionTabbed;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}

const layoutFullWidth = {
  sections: [
    {
      width: 100
    }
  ]
};

export function getCreateLayoutDefinition(vue) {
  return {
    groups: [layoutFullWidth]
  };
}

export function getEditLayoutDefinition(vue) {
  return {
    groups: [layoutFullWidth, layoutFullWidth]
  };
}
