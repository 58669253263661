import MDinput from '@/components/MDinput';
import Select from '@/components/DSE/Select';
import {
  validatorConditionalRequired,
  validatorRequireNumber,
  validatorConditionalRequiredNumber,
  validatorEmail
} from '@/utils/validators';
import { CouponType, ScratchAndWinBatchState, ScratchAndWinPriceType } from '../../../../utils/constants';
import PickRec from '@/components/DSE/PickRec';
import TextArea from '@/components/DSE/TextArea';

export function getFormDefinition(vue) {
  return {
    scratchAndWinPriceType: {
      type: Select,
      defaultValue: ScratchAndWinPriceType.CouponForNextOrder,
      props: {
        caption: 'scratchAndWin.scratchAndWinPriceType',
        valueField: 'code',
        labelField: 'description',
        loading: vue.$store.getters['option/isLoading'],
        options: vue.$store.getters['option/loadedOptions']('scratchAndWinPriceType'),
        required: true
      },
      rules: [{ validator: validatorRequireNumber }]
    },
    couponType: {
      type: Select,
      defaultValue: CouponType.FreeProduct,
      props: {
        caption: 'scratchAndWin.couponType',
        valueField: 'code',
        labelField: 'description',
        loading: vue.$store.getters['option/isLoading'],
        required: true
      },
      dynamicProps: form => ({
        options: vue.allowedCouponTypeOptions,
        visible: form.scratchAndWinPriceType === ScratchAndWinPriceType.CouponForNextOrder
      }),
      valueOnFormChange: (form, oldvalue) => {
        if (form.scratchAndWinPriceType !== ScratchAndWinPriceType.CouponForNextOrder) {
          return null;
        }
        return oldvalue;
      },
      dynamicRules: form => [
        {
          validator: validatorConditionalRequiredNumber(
            form.scratchAndWinPriceType === ScratchAndWinPriceType.CouponForNextOrder
          )
        }
      ]
    },
    productID: {
      type: PickRec,
      defaultValue: null,
      props: {
        caption: 'scratchAndWin.product',
        valueField: 'id',
        labelField: 'name',
        inputField: 'name',
        maxlength: 64,
        columns: [{ field: 'name', label: 'common.name' }],
        entity: 'Product',
        usePagination: true,
        hideInput: false,
        suggest: true,
        filters: { togoYN: 0, onlineYN: 1, tasteYN: 0 }
      },
      dynamicProps: form => ({
        visible: form.couponType === CouponType.FreeProduct
      }),
      dynamicRules: form => [
        {
          validator: validatorConditionalRequired(form.couponType === CouponType.FreeProduct)
        }
      ],
      valueOnFormChange: (form, oldvalue) => {
        if (form.couponType !== CouponType.FreeProduct) {
          return null;
        }
        return oldvalue;
      }
    },
    winText: {
      type: TextArea,
      defaultValue: '',
      props: {
        maxRows: 1024,
        caption: 'scratchAndWin.winTextGrandPrice'
      },
      dynamicProps: form => ({
        visible: form.scratchAndWinPriceType === ScratchAndWinPriceType.GrandPrize
      }),
      dynamicRules: form => [
        {
          validator: validatorConditionalRequired(form.scratchAndWinPriceType === ScratchAndWinPriceType.GrandPrize)
        }
      ]
    },
    sendPriceNotificationTo: {
      type: MDinput,
      defaultValue: '',
      props: {
        type: 'email',
        caption: 'scratchAndWin.sendPriceNotificationTo'
      },
      dynamicProps: form => ({
        visible: form.scratchAndWinPriceType === ScratchAndWinPriceType.GrandPrize
      }),
      rules: [
        {
          validator: validatorEmail
        }
      ],
      dynamicRules: form => [
        {
          validator: validatorConditionalRequired(form.scratchAndWinPriceType === ScratchAndWinPriceType.GrandPrize)
        }
      ]
    },
    discountPercentage: {
      type: MDinput,
      defaultValue: null,
      props: {
        caption: 'scratchAndWin.discountPercentage',
        type: 'percentage',
        precision: 1
      },
      dynamicProps: form => ({
        visible: form.couponType === CouponType.DiscountPercentage
      }),
      valueOnFormChange: (form, oldvalue) => {
        if (form.couponType !== CouponType.DiscountPercentage) {
          return null;
        }
        return oldvalue;
      },
      dynamicRules: form => [
        { validator: validatorConditionalRequired(form.couponType === CouponType.DiscountPercentage) }
      ]
    },
    numberOfCards: {
      type: MDinput,
      props: {
        type: 'number',
        min: 0,
        max: 999999,
        precision: 0,
        caption: 'scratchAndWin.numberOfCards',
        required: true
      },
      dynamicProps: form => ({
        readonly:
          form.batchState === ScratchAndWinBatchState.Generating ||
          form.batchState === ScratchAndWinBatchState.Generated
      }),
      rules: [{ validator: validatorRequireNumber }]
    },
    batchState: {
      type: Select,
      props: {
        caption: 'scratchAndWin.batchState',
        valueField: 'code',
        labelField: 'description',
        loading: vue.$store.getters['option/isLoading'],
        options: vue.$store.getters['option/loadedOptions']('scratchAndWinBatchState'),
        readonly: true
      },
      dynamicProps: form => ({
        visible: form.batchState ?? false
      })
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}
